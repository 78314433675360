/* Custom styles for the dashboard */
.sidebar {
  min-height: 100vh;
}

.content {
  padding: 20px;
}

/* .box {
  margin-bottom: 20px;
} */

.box-content-1 {
  background-color: #f8f9fa;
  border: 2px solid #000000;
  margin: 20px 5px 20px 20px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  min-height: 35vh;
  max-height: 50vh;
}

.box-content-2 {
  background-color: #f8f9fa;
  border: 2px solid #000000;
  /* padding: 100px; */
  margin: 20px 5px 20px 20px;
  text-align: center;
  /* border-radius: 5px; */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  min-height: 35vh;
  max-height: 50vh;
}
.box-content-3 {
  background-color: #f8f9fa;
  border: 2px solid #000000;
  /* padding: 100px; */
  margin: 20px;
  text-align: center;
  /* border-radius: 5px; */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  min-height: 35vh;
  max-height: 50vh;
}
.box-content-tb {
  background-color: #f8f9fa;
  border: 2px solid #000000;
  margin: 20px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  min-height: 36vh;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .sidebar {
    min-height: auto;
  }
}