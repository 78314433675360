/* กำหนด style สำหรับแท็ก p และ heading (h1-h6) */
p,  h2, h3, h4, h5, h6 {
    margin-top: 0;          /* กำหนดให้ margin-top เป็น 0 */
    font-size: inherit;     /* กำหนดให้ขนาดฟอนต์เป็นค่าที่มาจาก parent */
    font-weight: inherit;   /* กำหนดให้ความหนาของฟอนต์เป็นค่าที่มาจาก parent */
    font-family: 'Noto Sans Thai', sans-serif;
    font-size:'14px';
}

.TableWrapper {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}